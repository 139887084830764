import React, { Component } from 'react'

// Media 

import Envio from './../../assets/portes_es.png'

//babu
import escovas from './../../assets/produtos/comprar/escovas.png'
import escovaskids from './../../assets/produtos/comprar/escovaskids.png'
import canas from './../../assets/produtos/comprar/canas-transportadora.png'
import cotonetes from './../../assets/produtos/comprar/cotonetes.png'
import konjac from './../../assets/produtos/comprar/esponjakonjac.png'
import fiodental from './../../assets/produtos/comprar/fiodental.png'
import cabelo from './../../assets/produtos/comprar/cabelo.png'
import pasta from './../../assets/produtos/comprar/pastadedentes.png'



//babulife
import talheres from './../../assets/produtos/comprar/c-talheres.png'
import estojo from './../../assets/produtos/comprar/c-estojo.png'
import coffeespoons from './../../assets/produtos/comprar/c-coffeespoons.png'
import straw from './../../assets/produtos/comprar/palhinha.png'
import colhergarfo from './../../assets/produtos/comprar/c-forkspoon.png'

import logolife from "./../../assets/logos/lifelogo.png"
import sellogo from "./../../assets/logos/sellogo.png"

import fotoproduct from './../../assets/produtos/foto-produtos.png' //foto background
import lifeproduct from './../../assets/produtos/kitshot.jpg'



class Comprar extends Component {
    constructor(props){
        super(props)
        this.state={
            higiene:[
                {id:"escova", name: "Cepillos de dientes", img:escovas, link:"https://shop.babueco.com/es/produto/cepillos-de-dientes/" },
                {id:"kids", name:"Cepillos de dientes infantiles", img:escovaskids, link:"https://shop.babueco.com/es/produto/cepillos-de-dientes-kids/" },
                {id:"cana", name: "Estuche para cepillo de dientes", img:canas, link:"https://shop.babueco.com/es/produto/estuche-para-cepillo/" },
                {id:"fio", name: "Hilo dental", img:fiodental, link:"https://shop.babueco.com/es/produto/hilo-dental/" },
                {id:"cotonetes", name:"Bastoncillos de bambú ", img:cotonetes, link:"https://shop.babueco.com/es/produto/bastoncillos/" },
                {id:"konjac", name:"Esponja konjac", img:konjac, link:"https://shop.babueco.com/es/produto/esponja-de-konjac-es/" }, 
                {id:"Cepillopelo", name:"Cepillo de Pelo", img:cabelo, link:"https://shop.babueco.com/es/produto/cepillo-de-pelo/" },
                {id:"pastadedientes", name:"Pasta de dientes", img:pasta, link:"https://shop.babueco.com/es/produto/pasta-de-dientes/" },

                

            ],
            babulife:[
                {id:"palhinhas", name:"Pajitas", img:straw, link:"https://shop.babueco.com/es/produto/pajitas/" },
                {id:"colhercafe", name:"Cucharas de café", img:coffeespoons, link:"https://shop.babueco.com/es/produto/cucharas-de-cafe/" },
                {id:"talheres", name:"Tenedor-cuchara", img:colhergarfo, link:"https://shop.babueco.com/es/produto/cuchara-tenedor/" },
                {id:"talheres", name:"Cubiertos", img:talheres, link:"https://shop.babueco.com/es/produto/cubiertos/" },
                {id:"estojo", name:"Kit de comida",img:estojo, link:" https://shop.babueco.com/es/produto/kit-de-comida/" },

            ],
            switch:"higiene",
        }
        this.handleSwitch = this.handleSwitch.bind(this);
    }

handleSwitch(prop){
    let nextstate = prop;
    this.setState({switch:nextstate})
}

  render() {
    return (
        <main className="titlewrapper">
            <section className="hero titlesec">
                <div className="cta__">
                    <div className="cta__image"> <img className="diagonal--inverted" src={this.state.switch === "higiene" ? fotoproduct : lifeproduct} alt=""/></div>
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <h2>Tienda</h2>
                                <p>Los productos Babu pueden ser parte de tu vida y contribuir a la protección de nuestro planeta. ¡Está atento a todas las novedades!</p>
                            </div>
                        </div>
                </div>
            </section>
            <header className="menu__comprar menu__wrapper">
                <header className="produtos__header">
                    <div className="header__content">
                        <div className={this.state.switch === "higiene" ? "active " : " "}  onClick={this.handleSwitch.bind(this,"higiene")}><img src={sellogo} alt="" /></div>
                        <div className={this.state.switch === "babulife" ?  "active" : " "} onClick={this.handleSwitch.bind(this,"babulife")}><img src={logolife} alt="" /></div>
                    </div>
                </header>
            </header>
            <section className="item-list">
            {this.state.switch === "higiene" ? 
                this.state.higiene.map((item, index)=>{
                    return(
                        <div  key={index + "item"} className="items_container">
                            <div className="items_content">
                                <div className="item">
                                    <img src={item.img} alt=""/>
                                    <a href={item.link} ><div className="item_link">{item.name}</div></a>
                                </div>
                            </div>
                        </div>
                    )
                })
                :
                this.state.babulife.map((item, index)=>{
                    return(
                        <div  key={index + "item"} className="items_container">
                            <div className="items_content">
                                <div className="item">
                                    <img src={item.img} alt=""/>
                                    <a href={item.link} ><div className="item_link">{item.name}</div></a>
                                </div>
                            </div>
                        </div>
                    )
                })
            }

            </section>
            <section className="envio-section">
                <div className="envio-img" >
                    <img src={Envio}  alt=""/>
                </div>
            </section>


        </main>
    )
  }
}

export default Comprar