import React, { Component } from 'react'
import { Route, Link, Switch,  } from "react-router-dom";

/* componentes */


//Higiene

import Escova from './../components/produtos/higiene/escova'
import Canas from './../components/produtos/higiene/canas'
import Fio from './../components/produtos/higiene/fio'
import konjac from './../components/produtos/higiene/konjac'
import Swabs from './../components/produtos/higiene/swabs'
import Cabelo from './../components/produtos/higiene/cabelo'
import Pasta from './../components/produtos/higiene/pasta'



//babu-life
import Straw from './../components/produtos/babulife/straws'
import Colhercafe from './../components/produtos/babulife/colhercafe'
import Colhergarfo from './../components/produtos/babulife/colhergarfo'
import Talheres from './../components/produtos/babulife/talheres'
import Estojo from './../components/produtos/babulife/estojo'




//Images 

import seta from './../../assets/icons/seta.png'

import fotoproduct from './../../assets/produtos/brushshot.png'
import lifeproduct from './../../assets/produtos/kitshot.jpg'

import logolife from "./../../assets/logos/lifelogo.png"
import sellogo from "./../../assets/logos/sellogo.png"

const ListItem = ({ to, name,}) => (
    <Route
      path={to}
      children={({ match }) => (
        <Link to={to}>
            <li className={match ? "active" : ""}>
                {name   }
            </li>
        </Link>
      )}
    />
  );



class Produtos extends Component {
    constructor(props){
        super(props)
        this.state={
            product: "brush",
            switch:"higiene",
            openClose:false,

            higienepath: [
                    "/productos/cepillos",
                    "/productos/estuchecepillo",
                    "/productos/hilo",
                    "/productos/bastoncillos",                    
                    "/productos/konjac",
                    "/productos/cepillodepelo",
                    "/productos/pastadedientes",



                ],
            spot_higiene:[
                {to:"/productos/cepillos", name:"Cepillos de dientes" },
                {to:"/productos/estuchecepillo", name: "Estuche para cepillo"},
                {to:"/productos/hilo", name: "Hilo dental"},
                {to:"/productos/bastoncillos", name:"Bastoncillos"},
                {to:"/productos/konjac", name:"Esponja konjac"},
                {to:"/productos/cepillodepelo", name:"Cepillo de pelo"},
                {to:"/productos/pastadedientes", name:"Pasta de dientes"},
            ],
            spot_babulife:[
                {to:"/productos/pajitas", name:"Pajitas"},
                {to:"/productos/cucharascafe",name:"Cucharas de café"},
                {to:"/productos/tenedorcuchara", name:"Cuchara-tenedor"},
                {to:"/productos/cubiertos", name:"Cubiertos"},
                {to:"/productos/estuche", name:"Kit de comida"},
            ]
        }
        this.handleSwitchState = this.handleSwitchState.bind(this);
        this.handleSwitch = this.handleSwitch.bind(this);   
        this.Opener = this.Opener.bind(this)

    }

    componentDidMount(){
        if (this.state.higienepath.includes(this.props.location.pathname) || this.props.location.pathname === "/productos" ){
            this.setState({
                switch:"higiene"
            })
        }
        else(
            this.setState({
                switch:"babulife"
            })
        )
    }

    handleSwitchState(prop){
        let nextstate = this.state.product;
        nextstate = prop;
        this.setState({product:nextstate})
    }

    handleSwitch(prop){
        let nextstate = prop;
        this.setState({
            switch:nextstate,
        })
    }
    Opener(){
        let openClose = !this.state.openClose
        this.setState({openClose:openClose}) 
    }

    handleSwitchOpener(){
        
    }


  render() {
    return (
        <section className="titlewrapper">
            <section className="hero titlesec">
                <div className="cta__">
                    <div className="cta__image"> <img className="diagonal--inverted" src={this.state.switch === "higiene" ? fotoproduct : lifeproduct} alt=""/></div>
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <h2>Nuestros productos</h2>
                                <p>Los productos Babu están hechos de materiales naturales y renovables, siendo una excelente alternativa ecológica al plástico</p>
                            </div>
                        </div>
                </div>
            </section>
            <header className="menu__comprar menu__wrapper">
                <header className="produtos__header">
                    <div className="header__content">
                        <Link to="/productos/cepillos"><div className={this.state.switch === "higiene" ? "active " : " "}  onClick={this.handleSwitch.bind(this,"higiene")}><img src={sellogo} alt="" /></div></Link>
                        <Link to="/productos/pajitas"><div className={this.state.switch === "babulife" ?  "active" : " "} onClick={this.handleSwitch.bind(this,"babulife")}><img src={logolife} alt="" /></div></Link>
                    </div>
                </header>
            </header>
                <div className="menu-icon" ><img className={this.state.openClose ? "menuopener" : "menuopener open"}   onClick={this.Opener.bind(this)} src={seta} alt=""/></div>
                {this.state.switch ==="higiene" ? 
                    <ul className={this.state.openClose ? "menu__comprar--container" : "menu__comprar--container open"}>
                            {this.state.spot_higiene.map((item,index)=>{
                                return(
                                    <ListItem onClick={()=>this.Opener()} key={item.name + index} to={item.to} name={item.name} />
                                )
                            })}
                    </ul>

                    :

                    <ul className={this.state.openClose ? "menu__comprar--container" : "menu__comprar--container open"}>
                        {this.state.spot_babulife.map((item,index)=>{
                            return(
                                <ListItem onClick={()=>this.Opener()} key={item.name + index} to={item.to} name={item.name} />
                            )
                        })}
                    </ul>
                
            
            }

            

            <section>
                <Switch>
                    <Route exact path="/productos" component={Escova} />
                    <Route path="/productos/cepillos" component={Escova}/>
                    <Route path="/productos/estuchecepillo" component={Canas}/>
                    <Route path="/productos/konjac" component={konjac}/>
                    <Route path="/productos/hilo" component={Fio}/>
                    <Route path="/productos/bastoncillos" component={Swabs}/>
                    <Route path="/productos/cepillodepelo" component={Cabelo}/>
                    <Route path="/productos/pastadedientes" component={Pasta}/>
                    
                    
                    <Route path="/productos/pajitas" component={Straw}/>
                    <Route path="/productos/cucharascafe" component={Colhercafe}/>
                    <Route path="/productos/tenedorcuchara" component={Colhergarfo}/>
                    <Route path="/productos/cubiertos" component={Talheres}/>
                    <Route path="/productos/estuche" component={Estojo}/>
                    
                </Switch>
            </section>
        </section>
    )
  }
}

export default Produtos
