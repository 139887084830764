import React, { Component } from 'react'

// Media 

import Envio from './../../assets/portes_fr.png'

//babu
import escovas from './../../assets/produtos/comprar/escovas.png'
import escovaskids from './../../assets/produtos/comprar/escovaskids.png'
import canas from './../../assets/produtos/comprar/canas-transportadora.png'
import cotonetes from './../../assets/produtos/comprar/cotonetes.png'
import konjac from './../../assets/produtos/comprar/esponjakonjac.png'
import fiodental from './../../assets/produtos/comprar/fiodental.png'
import cabelo from './../../assets/produtos/comprar/cabelo.png'
import pasta from './../../assets/produtos/comprar/pastadedentes.png'



//babulife
import talheres from './../../assets/produtos/comprar/c-talheres.png'
import estojo from './../../assets/produtos/comprar/c-estojo.png'
import coffeespoons from './../../assets/produtos/comprar/c-coffeespoons.png'
import straw from './../../assets/produtos/comprar/palhinha.png'
import colhergarfo from './../../assets/produtos/comprar/c-forkspoon.png'


import logolife from "./../../assets/logos/lifelogo.png"
import sellogo from "./../../assets/logos/sellogo.png"

import fotoproduct from './../../assets/produtos/foto-produtos.png' //foto background
import lifeproduct from './../../assets/produtos/kitshot.jpg'





class Comprar extends Component {
    constructor(props){
        super(props)
        this.state={
            higiene:[
                {id:"escova", name:"Brosses à dents", img:escovas, link:"https://shop.babueco.com/fr/produto/brosses-a-dents/" },
                {id:"kids", name:"Brosses à dents pour enfants", img:escovaskids, link:"https://shop.babueco.com/fr/produto/brosses-a-dents-kids/" },
                {id:"cana", name:"Étuis de transport", img:canas, link:"https://shop.babueco.com/fr/produto/etui-de-transport/" },
                {id:"fio", name:"Fil dentaire", img:fiodental, link:"https://shop.babueco.com/fr/produto/fil-dentaire/" },
                {id:"cotonetes", name:"Coton-tige", img:cotonetes, link:" https://shop.babueco.com/fr/produto/cotons-tiges/" },
                {id:"konjac", name:"Éponges konjac", img:konjac, link:"https://shop.babueco.com/fr/produto/eponge-konjac/" },
                {id:"escovacabelo", name:"Brosse à cheveux", img:cabelo, link:"https://shop.babueco.com/fr/produto/brosse-a-cheveux/" },
                {id:"pastadedentes", name:"Dentrifice", img:pasta, link:"https://shop.babueco.com/fr/produto/dentrifice/" },
                

            ],
            babulife:[
                {id:"palhinhas", name:"Pailles à boire", img:straw, link:"https://shop.babueco.com/fr/produto/pailles-a-boire/" },
                {id:"colhercafe", name:"Cuillères à café", img:coffeespoons, link:"https://shop.babueco.com/fr/produto/cuilleres-a-cafe/" },
                {id:"talheres", name:"Cuillère Fourchette", img:colhergarfo, link:"https://shop.babueco.com/fr/produto/cuillere-fourchette/" },
                {id:"talheres", name:"Couverts", img:talheres, link:"https://shop.babueco.com/fr/produto/couverts/" },
                {id:"estojo", name:"Étuis à couverts", img:estojo, link:"https://shop.babueco.com/fr/produto/etuis-a-couverts/" },

            ],
            switch:"higiene",
        }
        this.handleSwitch = this.handleSwitch.bind(this);
    }

handleSwitch(prop){
    let nextstate = prop;
    this.setState({switch:nextstate})
}

  render() {
    return (
        <main className="titlewrapper">
            <section className="hero titlesec">
                <div className="cta__">
                    <div className="cta__image"> <img className="diagonal--inverted" src={this.state.switch === "higiene" ? fotoproduct : lifeproduct} alt=""/></div>
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <h2>Boutique</h2>
                                <p>Les produits Babu peuvent faire partie de votre vie et contribuer à la protection de notre planète. Restez à l'écoute de toutes les nouveautés !</p>
                            </div>
                        </div>
                </div>
            </section>
            <header className="menu__comprar menu__wrapper">
                <header className="produtos__header">
                    <div className="header__content">
                        <div className={this.state.switch === "higiene" ? "active " : " "}  onClick={this.handleSwitch.bind(this,"higiene")}><img src={sellogo} alt="" /></div>
                        <div className={this.state.switch === "babulife" ?  "active" : " "} onClick={this.handleSwitch.bind(this,"babulife")}><img src={logolife} alt="" /></div>
                    </div>
                </header>
            </header>
            <section className="item-list">
            {this.state.switch === "higiene" ? 
                this.state.higiene.map((item, index)=>{
                    return(
                        <div  key={index + "item"} className="items_container">
                            <div className="items_content">
                                <div className="item">
                                    <img src={item.img} alt=""/>
                                    <a href={item.link} ><div className="item_link">{item.name}</div></a>
                                </div>
                            </div>
                        </div>
                    )
                })
                :
                this.state.babulife.map((item, index)=>{
                    return(
                        <div  key={index + "item"} className="items_container">
                            <div className="items_content">
                                <div className="item">
                                    <img src={item.img} alt=""/>
                                    <a href={item.link} ><div className="item_link">{item.name}</div></a>
                                </div>
                            </div>
                        </div>
                    )
                })
            }

            </section>
            <section className="envio-section">
                <div className="envio-img" >
                    <img src={Envio}  alt=""/>
                </div>
            </section>


        </main>
    )
  }
}

export default Comprar