import React, { Component } from 'react'
import { Route, Link, Switch,  } from "react-router-dom";

/* componentes */


//Higiene

import Escova from './../components/produtos/higiene/escova'
import Canas from './../components/produtos/higiene/canas'
import Fio from './../components/produtos/higiene/fio'
import konjac from './../components/produtos/higiene/konjac'
import Swabs from './../components/produtos/higiene/swabs'
import Cabelo from '../components/produtos/higiene/cabelo';
import Pasta from '../components/produtos/higiene/pasta';





//babu-life
import Straw from './../components/produtos/babulife/straws'
import Colhercafe from './../components/produtos/babulife/colhercafe'
import Colhergarfo from './../components/produtos/babulife/colhergarfo'
import Talheres from './../components/produtos/babulife/talheres'
import Estojo from './../components/produtos/babulife/estojo'




//Images 

import seta from './../../assets/icons/seta.png'

import fotoproduct from './../../assets/produtos/brushshot.png'
import lifeproduct from './../../assets/produtos/kitshot.jpg'

import logolife from "./../../assets/logos/lifelogo.png"
import sellogo from "./../../assets/logos/sellogo.png"

const ListItem = ({ to, name,}) => (
    <Route
      path={to}
      children={({ match }) => (
        <Link to={to}>
            <li className={match ? "active" : ""}>
                {name   }
            </li>
        </Link>

      )}
    />
  );



class Produtos extends Component {
    constructor(props){
        super(props)
        this.state={
            product: "brush",
            switch:"higiene",
            openClose:false,

            higienepath: [
                "/products/brush",
                "/products/cases",
                "/products/floss",
                "/products/swabs",                    
                "/products/konjac",
                "/products/hairbrush",
                "/products/toothpaste",
            ],
            spot_higiene:[
                {to:"/products/brush", name:"Toothbrush"},
                {to:"/products/cases", name:"Travel cases"},
                {to:"/products/floss", name:"Dental floss"},
                {to:"/products/swabs", name:"Cotton swabs"},
                {to:"/products/konjac", name:"Konjac sponges"},
                {to:"/products/hairbrush", name:"Hairbrush"},
                {to:"/products/toothpaste", name:"Toothpaste"},
            ],
            spot_babulife:[
                {to:"/products/straws", name:"Straws"},
                {to:"/products/coffeespoons", name:"Coffee spoons"},
                {to:"/products/spoonfork", name:"Spoon-fork"},
                {to:"/products/cutlery", name:"Cutlery"},
                {to:"/products/mealcase", name:"Meal case"},
            ]
        }
        this.handleSwitchState = this.handleSwitchState.bind(this);
        this.handleSwitch = this.handleSwitch.bind(this);   
        this.Opener = this.Opener.bind(this)

    }

    componentDidMount(){
        if (this.state.higienepath.includes(this.props.location.pathname) || this.props.location.pathname === "/products" ){
            this.setState({
                switch:"higiene"
            })
        }
        else(
            this.setState({
                switch:"babulife"
            })
        )
    }

    handleSwitchState(prop){
        let nextstate = this.state.product;
        nextstate = prop;
        this.setState({product:nextstate})
    }

    handleSwitch(prop){
        let nextstate = prop;
        this.setState({
            switch:nextstate,
        })
    }
    Opener(){
        let openClose = !this.state.openClose
        this.setState({openClose:openClose}) 
    }

    handleSwitchOpener(){
        
    }


  render() {
    return (
        <section className="titlewrapper">
            <section className="hero titlesec">
                <div className="cta__">
                    <div className="cta__image"> <img className="diagonal--inverted" src={this.state.switch === "higiene" ? fotoproduct : lifeproduct} alt=""/></div>
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <h2>Our products</h2>
                                <p>Babu products are made of natural and renewable materials, which are an excellent ecological alternative to plastic.</p>
                            </div>
                        </div>
                </div>
            </section>
            <header className="menu__comprar menu__wrapper">
                <header className="produtos__header">
                    <div className="header__content">
                        <Link to="/products/brush"><div className={this.state.switch === "higiene" ? "active " : " "}  onClick={this.handleSwitch.bind(this,"higiene")}><img src={sellogo} alt="" /></div></Link>
                        <Link to="/products/straws"><div className={this.state.switch === "babulife" ?  "active" : " "} onClick={this.handleSwitch.bind(this,"babulife")}><img src={logolife} alt="" /></div></Link>
                    </div>
                </header>
            </header>
                <div className="menu-icon" ><img className={this.state.openClose ? "menuopener" : "menuopener open"}   onClick={this.Opener.bind(this)} src={seta} alt=""/></div>
                {this.state.switch ==="higiene" ? 
                    <ul className={this.state.openClose ? "menu__comprar--container" : "menu__comprar--container open"}>
                            {this.state.spot_higiene.map((item,index)=>{
                                return(
                                    <ListItem onClick={()=>this.Opener()} key={item.name + index} to={item.to} name={item.name} />
                                )
                            })}
                    </ul>

                    :

                    <ul className={this.state.openClose ? "menu__comprar--container" : "menu__comprar--container open"}>
                        {this.state.spot_babulife.map((item,index)=>{
                            return(
                                <ListItem onClick={()=>this.Opener()} key={item.name + index} to={item.to} name={item.name} />
                            )
                        })}
                    </ul>
                
            
            }

            <section>
                <Switch>
                    <Route exact path="/products" component={Escova} />
                    <Route path="/products/brush" component={Escova}/>
                    <Route path="/products/cases" component={Canas}/>
                    <Route path="/products/konjac" component={konjac}/>
                    <Route path="/products/floss" component={Fio}/>
                    <Route path="/products/swabs" component={Swabs}/>
                    <Route path="/products/hairbrush" component={Cabelo}/>
                    <Route path="/products/toothpaste" component={Pasta}/>


                    <Route path="/products/straws" component={Straw}/>
                    <Route path="/products/coffeespoons" component={Colhercafe}/>
                    <Route path="/products/spoonfork" component={Colhergarfo}/>
                    <Route path="/products/cutlery" component={Talheres}/>
                    <Route path="/products/mealcase" component={Estojo}/>
                    
                </Switch>
            </section>
        </section>
    )
  }
}

export default Produtos
