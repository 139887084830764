import React, { Component } from 'react'
import $ from "jquery";
 

import fotoproduct from './../../assets/produtos/onde.jpg'


import Online02 from './../../assets/parceiros/online/biobrassica.png'
import Online03 from './../../assets/parceiros/online/bosque_feliz.jpg'
import Online04 from './../../assets/parceiros/online/celeiro_integral.jpg'
import Online05 from './../../assets/parceiros/online/centro_vegetariano.jpg'
import Online06 from './../../assets/parceiros/online/essencia_nature.png'
import Online07 from './../../assets/parceiros/online/green_diamond.png'
import Online08 from './../../assets/parceiros/online/loja_vegetariana.png'
import Online09 from './../../assets/parceiros/online/luadia.jpg'
import Online10 from './../../assets/parceiros/online/luxsmile.png'
import Online11 from './../../assets/parceiros/online/mae_natureza.png'
import Online12 from './../../assets/parceiros/online/mercado_portugues.png'
import Online13 from './../../assets/parceiros/online/miristica.png'
import Online14 from './../../assets/parceiros/online/muah.png'
import Online16 from './../../assets/parceiros/online/ovo.png'
import Online17 from './../../assets/parceiros/online/pegada_verde.png'
import Online18 from './../../assets/parceiros/online/planetiers.png'
import Online19 from './../../assets/parceiros/online/tartaruguita.png'
import Online20 from './../../assets/parceiros/online/terra_batida.png'
import Online21 from './../../assets/parceiros/online/malu.jpg'
import Online22 from './../../assets/parceiros/online/myecobaby.jpg'
import Online23 from './../../assets/parceiros/online/portasentidos.png'
import Online24 from './../../assets/parceiros/online/reUsable.jpg'
import Online25 from './../../assets/parceiros/online/greenvibe.png'
import Online26 from './../../assets/parceiros/online/growild.png'
import Online27 from './../../assets/parceiros/online/caminhozero.png'
import Online28 from './../../assets/parceiros/online/daminhacasinha.png'
import Online29 from './../../assets/parceiros/online/logo-ek.png'
import Online30 from './../../assets/parceiros/online/efeitoverde.jpg'
import Online31 from './../../assets/parceiros/online/riachos.jpg'
import Online32 from './../../assets/parceiros/online/girassol.png'
import Online33 from './../../assets/parceiros/online/ideias_saudaveis.png'
import Online34 from './../../assets/parceiros/online/ladom.jpg'
import Online35 from './../../assets/parceiros/online/lolitakids.png'
import Online36 from './../../assets/parceiros/online/mariamar.png'
import Online37 from './../../assets/parceiros/online/mimobox.png'
import Online38 from './../../assets/parceiros/online/naturfil.png'
import Online39 from './../../assets/parceiros/online/nutri.png'
import Online40 from './../../assets/parceiros/online/puroverde.png'
import Online41 from './../../assets/parceiros/online/veganvibe.png'
import Online42 from './../../assets/parceiros/online/mar_estorias.png'
import Online43 from './../../assets/parceiros/online/organiko.png'
import Online44 from './../../assets/parceiros/online/mipmed.png'


import Azores01 from './../../assets/parceiros/acores/pa_natur.jpg'
import Azores02 from './../../assets/parceiros/acores/espaco_terra_cosmos.jpg'
import Azores03 from './../../assets/parceiros/acores/bioforma.svg'
import Azores04 from './../../assets/parceiros/acores/espacocena.jpg'
import Azores05 from './../../assets/parceiros/acores/mercadoervas.png'

import Aveiro01 from './../../assets/parceiros/aveiro/farmacia_carmindo_lamy.png'
import Aveiro02 from './../../assets/parceiros/aveiro/mercearia_santiago.jpg'
import Aveiro03 from './../../assets/parceiros/aveiro/natur_ala.png'
import Aveiro04 from './../../assets/parceiros/aveiro/naturvida.png'
import Aveiro05 from './../../assets/parceiros/aveiro/vertente_dos_sabores.jpg'
import Aveiro06 from './../../assets/parceiros/aveiro/naturria.jpg'
import Aveiro07 from './../../assets/parceiros/aveiro/cortegaca.png'

import Beja01 from './../../assets/parceiros/beja/solvita_ervanaria.jpg'
import Beja02 from './../../assets/parceiros/beja/zmar.png'

import Braga01 from './../../assets/parceiros/braga/biobrassica.png'
import Braga02 from './../../assets/parceiros/braga/jaju.png'
import Braga03 from './../../assets/parceiros/braga/natural_bio.jpg'
import Braga04 from './../../assets/parceiros/braga/pedraflor.png'  
import Braga05 from './../../assets/parceiros/braga/biocelos.png'
import Braga06 from './../../assets/parceiros/braga/orto.jpg'
import Braga07 from './../../assets/parceiros/braga/infarma.png'
import Braga08 from './../../assets/parceiros/braga/biojordao.jpg'
import Braga09 from './../../assets/parceiros/braga/bluemall.jpg'


import Braganza01 from './../../assets/parceiros/braganza/morais.jpg'

import Castelo01 from './../../assets/parceiros/castelobranco/aldeia.png'
import Castelo02 from './../../assets/parceiros/castelobranco/terra_bio.jpg'

import Coimbra01 from './../../assets/parceiros/coimbra/bioescolha.jpg'
import Coimbra02 from './../../assets/parceiros/coimbra/graonatural.jpg'
import Coimbra03 from './../../assets/parceiros/coimbra/Cerdeira.png'
import Coimbra04 from './../../assets/parceiros/coimbra/vvgranel.jpg'

import Evora01 from './../../assets/parceiros/evora/manjerona.jpg'
import Evora02 from './../../assets/parceiros/evora/oxala.png'
import Evora03 from './../../assets/parceiros/evora/barrocal.svg'

import Faro01 from './../../assets/parceiros/faro/meusuper.jpg'
import Faro02 from './../../assets/parceiros/faro/mercearia_bio.png'
import Faro03 from './../../assets/parceiros/faro/Loja_Mundo_Saudavel.png'
import Faro04 from './../../assets/parceiros/faro/natur_boticae.jpg'
import Faro05 from './../../assets/parceiros/faro/ortonatura.jpg'
import Faro06 from './../../assets/parceiros/faro/outro_lado.jpg'
import Faro07 from './../../assets/parceiros/faro/retratos_aldeia.jpg'
import Faro08 from './../../assets/parceiros/faro/feijanito.jpg'
import Faro09 from './../../assets/parceiros/faro/graosamor.jpg'
import Faro10 from './../../assets/parceiros/faro/hortepimenta.jpg'
import Faro11 from './../../assets/parceiros/faro/maria.png'
import Faro12 from './../../assets/parceiros/faro/armaintegral.jpg'
import Faro13 from './../../assets/parceiros/faro/central.png'
import Faro14 from './../../assets/parceiros/faro/cardo.jpg'
import Faro15 from './../../assets/parceiros/faro/thingsnatura.jpg'

import Guarda01 from './../../assets/parceiros/guarda/adnbio.svg'
import Guarda02 from './../../assets/parceiros/guarda/sampaio.png'

import Leiria01 from './../../assets/parceiros/leiria/bio_center.png'
import Leiria02 from './../../assets/parceiros/leiria/bioescolha_Leiria.jpg'
import Leiria03 from './../../assets/parceiros/leiria/bios.png'
import Leiria04 from './../../assets/parceiros/leiria/equilibra.jpg'
import Leiria05 from './../../assets/parceiros/leiria/rosacaldas.jpg'
import Leiria06 from './../../assets/parceiros/leiria/granel.jpg'
import Leiria07 from './../../assets/parceiros/leiria/guimarota.jpg'
import Leiria08 from './../../assets/parceiros/leiria/origens.png'
import Leiria09 from './../../assets/parceiros/leiria/intermarche.png'
import Leiria10 from './../../assets/parceiros/leiria/tresp.jpg'
import Leiria11 from './../../assets/parceiros/leiria/granelams.jpg'



import Lisboa01 from './../../assets/parceiros/lisboa/agriloja.svg'
import Lisboa02 from './../../assets/parceiros/lisboa/amantedoequilibrio.png'
import Lisboa03 from './../../assets/parceiros/lisboa/Be_U.jpg'
import Lisboa04 from './../../assets/parceiros/lisboa/bioalma.jpg'
import Lisboa05 from './../../assets/parceiros/lisboa/courela.png'
import Lisboa06 from './../../assets/parceiros/lisboa/bioino.png'
import Lisboa08 from './../../assets/parceiros/lisboa/bolinha_de_sabao.jpg'
import Lisboa09 from './../../assets/parceiros/lisboa/bomercado.jpg'
import Lisboa10 from './../../assets/parceiros/lisboa/bosque_feliz.jpg'
import Lisboa11 from './../../assets/parceiros/lisboa/casa_a_granel.png'
import Lisboa12 from './../../assets/parceiros/lisboa/clinicas_viver.png'
import Lisboa13 from './../../assets/parceiros/lisboa/ervanaria_sjoao.jpg'
import Lisboa14 from './../../assets/parceiros/lisboa/metamorphoses.png'
import Lisboa15 from './../../assets/parceiros/lisboa/farmacia_cardote.jpg'
import Lisboa16 from './../../assets/parceiros/lisboa/LjConsigo.png'
import Lisboa17 from './../../assets/parceiros/lisboa/luadia.jpg'
import Lisboa18 from './../../assets/parceiros/lisboa/miosotis.jpg'
import Lisboa19 from './../../assets/parceiros/lisboa/porta88.jpg'
import Lisboa20 from './../../assets/parceiros/lisboa/somos_bio.png'
import Lisboa21 from './../../assets/parceiros/lisboa/thecru.png'
import Lisboa22 from './../../assets/parceiros/lisboa/wave269.jpg'
import Lisboa24 from './../../assets/parceiros/lisboa/granel.png'
import Lisboa25 from './../../assets/parceiros/lisboa/saborescomalma.png'
import Lisboa26 from './../../assets/parceiros/lisboa/farmaciavarela.jpg'
import Lisboa27 from './../../assets/parceiros/lisboa/biofrade.png'
import Lisboa28 from './../../assets/parceiros/lisboa/granelinha.jpg'
import Lisboa29 from './../../assets/parceiros/lisboa/benfica.png'
import Lisboa30 from './../../assets/parceiros/lisboa/vintenove.jpg'
import Lisboa31 from './../../assets/parceiros/lisboa/mlclinic.jpg'
import Lisboa32 from './../../assets/parceiros/lisboa/ecologica.jpg'
import Lisboa33 from './../../assets/parceiros/lisboa/carnide.jpg'
import Lisboa34 from './../../assets/parceiros/lisboa/docentro.png'
import Lisboa35 from './../../assets/parceiros/lisboa/confianca.png'
import Lisboa36 from './../../assets/parceiros/lisboa/luz.jpg'
import Lisboa37 from './../../assets/parceiros/lisboa/lisboa.jpg'
import Lisboa38 from './../../assets/parceiros/lisboa/gh.png'
import Lisboa39 from './../../assets/parceiros/lisboa/halomedic.png'
import Lisboa40 from './../../assets/parceiros/lisboa/zoo.png'
import Lisboa41 from './../../assets/parceiros/lisboa/livingwine.jpg'
import Lisboa42 from './../../assets/parceiros/lisboa/renovar.jpg'
import Lisboa43 from './../../assets/parceiros/lisboa/agua.jpg'
import Lisboa44 from './../../assets/parceiros/lisboa/bucelas.jpg'
import Lisboa45 from './../../assets/parceiros/lisboa/bukubaki.png'
import Lisboa46 from './../../assets/parceiros/lisboa/campo.jpg'
import Lisboa47 from './../../assets/parceiros/lisboa/despensa.jpg'
import Lisboa48 from './../../assets/parceiros/lisboa/natura.png'
import Lisboa49 from './../../assets/parceiros/lisboa/choupal.jpg'
import Lisboa50 from './../../assets/parceiros/lisboa/diabetico.jpg'
import Lisboa51 from './../../assets/parceiros/lisboa/naturgolfinho.jpg'
import Lisboa52 from './../../assets/parceiros/lisboa/ludi.png'
import Lisboa53 from './../../assets/parceiros/lisboa/girassol.png'


import Madeira01 from './../../assets/parceiros/madeira/freshbio.jpg'
import Madeira02 from './../../assets/parceiros/madeira/bioforma.svg'
import Madeira03 from './../../assets/parceiros/madeira/ideias.png'
import Madeira04 from './../../assets/parceiros/madeira/jacaranda.jpg'
import Madeira05 from './../../assets/parceiros/madeira/solobio.png'

import Portalegre01 from './../../assets/parceiros/portalegre/mercado.jpg'
import Portalegre02 from './../../assets/parceiros/portalegre/chambel.jpg'

import Porto01 from './../../assets/parceiros/porto/bio.jpg'
import Porto02 from './../../assets/parceiros/porto/greendiamond.png'
import Porto03 from './../../assets/parceiros/porto/medifarma.jpg'
import Porto04 from './../../assets/parceiros/porto/sonhos.png'
import Porto05 from './../../assets/parceiros/porto/biomercado.png'
import Porto06 from './../../assets/parceiros/porto/viver.jpg'
import Porto07 from './../../assets/parceiros/porto/macaroca.jpg'
import Porto08 from './../../assets/parceiros/porto/bolacha.jpg'
import Porto09 from './../../assets/parceiros/porto/yeat.png'
import Porto10 from './../../assets/parceiros/porto/vegana.jpg'
import Porto11 from './../../assets/parceiros/porto/nina.png'
import Porto12 from './../../assets/parceiros/porto/mira.jpg'

import Santarem01 from './../../assets/parceiros/santarem/biothromar.jpg'
import Santarem02 from './../../assets/parceiros/santarem/biovo.png'
import Santarem03 from './../../assets/parceiros/santarem/celeiro.png'
import Santarem04 from './../../assets/parceiros/santarem/tonsai.jpg'
import Santarem05 from './../../assets/parceiros/santarem/nutribem.jpg'
import Santarem06 from './../../assets/parceiros/santarem/pharmascalabis.png'
import Santarem07 from './../../assets/parceiros/santarem/nova.png'
import Santarem08 from './../../assets/parceiros/santarem/xo.jpg'
import Santarem09 from './../../assets/parceiros/santarem/central.jpg'
import Santarem10 from './../../assets/parceiros/santarem/terra.png'
import Santarem11 from './../../assets/parceiros/santarem/naturfil.jpg'
import Santarem12 from './../../assets/parceiros/santarem/lux.jpg'
import Santarem13 from './../../assets/parceiros/santarem/alma.jpg'

import Setubal01 from './../../assets/parceiros/setubal/essencia_nature.png'
import Setubal02 from './../../assets/parceiros/setubal/naturloja.png'
import Setubal03 from './../../assets/parceiros/setubal/gomes.jpg'
import Setubal04 from './../../assets/parceiros/setubal/pragal.jpg'
import Setubal05 from './../../assets/parceiros/setubal/bioalma.jpg'
import Setubal06 from './../../assets/parceiros/setubal/florsol.jpg'
import Setubal07 from './../../assets/parceiros/setubal/passado.jpg'
import Setubal08 from './../../assets/parceiros/setubal/biovilla.png'
import Setubal09 from './../../assets/parceiros/setubal/fontes.jpg'
import Setubal10 from './../../assets/parceiros/setubal/castica.jpg'
import Setubal11 from './../../assets/parceiros/setubal/horta.jpg'
import Setubal12 from './../../assets/parceiros/setubal/kolibrie.jpg'
import Setubal13 from './../../assets/parceiros/setubal/dezafios.png'
import Setubal14 from './../../assets/parceiros/setubal/art.jpg'
import Setubal15 from './../../assets/parceiros/setubal/graosfolhas.png'

import Viana01 from './../../assets/parceiros/vianadocastelo/pureza.jpg'
import Viana02 from './../../assets/parceiros/vianadocastelo/cerqueira.jpg'
import Viana03 from './../../assets/parceiros/vianadocastelo/lafamilia.jpg'

import Vila01 from './../../assets/parceiros/vilareal/levada.jpg'

import Viseu01 from './../../assets/parceiros/viseu/beirabio.png'
import Viseu02 from './../../assets/parceiros/viseu/lafonatura.jpg'
import Viseu03 from './../../assets/parceiros/viseu/viae.png'
import Viseu04 from './../../assets/parceiros/viseu/horta.jpg'
import Viseu05 from './../../assets/parceiros/viseu/rebel.jpg'

import Spain01 from './../../assets/parceiros/porto/greendiamond.png'
import Uk01 from './../../assets/parceiros/porto/greendiamond.png' 



import Portugal from './../../assets/paises/portugal.png'
import Spain from './../../assets/paises/espanha.png'
import Croatia from './../../assets/paises/croacia.png'
import Latvia from './../../assets/paises/letonia.png'
import Dutch from './../../assets/paises/dutch.png'
import Uk from './../../assets/paises/uk.png'
import Greek from './../../assets/paises/grecia.png'
import Swiss from './../../assets/paises/suica.png'
import Europe from './../../assets/paises/map.jpg'



class Resell extends Component {
  constructor(props){
    super(props)
    this.state={
        imageStart: Europe,
        imageText:"Estamos presentes um pouco por toda a parte do mundo! Clique nos botões à esquerda e descubra onde pode encontrar a Babu!",
        regionWithItems:"",
        resellers:[
          {
            region: "Online",
            resellers:[
              { id:"02", src:Online02,  loja: "Bio Brassica", link:"http://www.biobrassica.pt" },
              { id:"07", src:Lisboa06,  loja: "BIOino", link:"http://www.bioino.pt" },
              { id:"02", src:Santarem02,  loja: "BioVó", link:"http://www.biovo.pt" },
              { id:"04", src:Braga09,  loja: "Blue Mall Sustainable", link:"https://bluemallsustainable.com/" },
              { id:"03", src:Online03,  loja: "Bosque Feliz", link:"http://www.bosquefeliz.pt" },
              { id:"06", src:Online27,  loja: "Caminho Zero", link:"https://www.caminhozero.pt/" },
              { id:"04", src:Online04,  loja: "Celeiro Integral", link:"http://www.celeirointegral.pt" },
              { id:"05", src:Online05,  loja: "Centro Vegetariano", link:"http://www.centrovegetariano.org/loja/" },
              { id:"06", src:Online28,  loja: "Da minha casinha", link:"https://www.daminhacasinha.com/" },
              { id:"06", src:Online29,  loja: "Ecological kids", link:"https://www.ecologicalkids.pt/" },
              { id:"06", src:Online30,  loja: "Efeito Verde", link:"https://www.efeitoverde.com/" },
              { id:"06", src:Online31,  loja: "Ervanária Riachos", link:"https://www.facebook.com/ervanariariachos/" },
              { id:"06", src:Online06,  loja: "Essencia Nature", link:"http://www.essencianature.pt" },
              { id:"06", src:Online32,  loja: "Girassol", link:"http://www.girassol.com/" },
              { id:"07", src:Online07,  loja: "Green Diamond", link:"http://www.greendiamond.pt" },
              { id:"24", src:Online25,  loja: "Greenvibe", link:"https://www.greenvibe.pt/" },
              { id:"25", src:Online26,  loja: "growwild", link:"https://growild.pt/" },
              { id:"06", src:Online33,  loja: "Ideias Saudaveis", link:"https://www.ideias-saudaveis.pt/" },
              { id:"06", src:Online34,  loja: "Lado M", link:"https://ladom.pt/" },
              { id:"08", src:Online08,  loja: "Loja Vegetariana", link:"https://lojavegetariana.pt" },
              { id:"06", src:Online35,  loja: "Lolita Kids Store", link:"https://www.lolitakidsstore.com/" },
              { id:"09", src:Online09,  loja: "Luadia Terapias", link:"https://luadiaterapias.wixsite.com/luadia" },
              { id:"10", src:Online10,  loja: "Lux Smile", link:"https://www.luxsmile.pt" },
              { id:"11", src:Online11,  loja: "Mãe Natureza", link:"https://www.maenatureza.pt" },
              { id:"26", src:Online21,  loja: "Malu", link:"https://malu-ecoshop.pt/" },
              { id:"01", src:Online42,  loja: "Mar d’Estórias", link:"http://www.mardestorias.com" },
              { id:"06", src:Online36,  loja: "Maria do Mar", link:"https://mariadomarshop.com/" },
              { id:"12", src:Online12,  loja: "Mercado Português", link:"https://www.mercadoportugues.pt/" },
              { id:"06", src:Online37,  loja: "Mimobox", link:"https://mimobox.pt/" },
              { id:"06", src:Online44,  loja: "Mipmed", link:"https://mipmed.com/" },
              { id:"13", src:Online13,  loja: "Miristica", link:"https://www.miristica.pt" },
              { id:"14", src:Online14,  loja: "Muah", link:"https://www.muah.pt" },
              { id:"21", src:Online22,  loja: "MyEcoBaby", link:"https://myecobaby.pt" },
              { id:"06", src:Online38,  loja: "Naturfil", link:"https://naturfil.pt/" },
              { id:"06", src:Online39,  loja: "Nutrisupply", link:"https://www.nutrisupply.pt/" },
              { id:"16", src:Online43,  loja: "organiko", link:"https://www.organiko.pt/" },
              { id:"16", src:Online16,  loja: "OVO", link:"https://www.o-v-o.pt" },
              { id:"17", src:Online17,  loja: "Pegada Verde", link:"https://www.pegada-verde.pt" },
              { id:"06", src:Santarem06,  loja: "Pharma Scalabis", link:"https://www.pharmascalabis.com.pt/" },
              { id:"18", src:Online18,  loja: "Planetiers", link:"https://www.planetiers.com" },
              { id:"22", src:Online23,  loja: "Porta dos Sentidos", link:"https://www.porta-dos-sentidos.pt/" },
              { id:"06", src:Online40,  loja: "Puro Verde", link:"https://www.puroverde-ecostore.com/" },
              { id:"19", src:Online19,  loja: "Tartaruguita", link:"https://www.tartaruguita.pt" },
              { id:"20", src:Online20,  loja: "Terra Batida", link:"https://www.terrabatida.pt" },
              { id:"23", src:Online24,  loja: "reUsable", link:"https://www.facebook.com/reusableportugal/" },
              { id:"06", src:Online41,  loja: "Vegan Vibe", link:"https://veganvibe.pt/" },
            ]
          },
          {
            region: "Farmácias",
            resellers:[
              { id:"30", src:Lisboa29,  loja: "Farmácia Benfica", link:"https://www.facebook.com/farmaciabenfica/" },
              { id:"30", src:Lisboa33,  loja: "Farmácia Carnide", link:"https://www.facebook.com/farmaciacarnide/" },
              { id:"01", src:Aveiro01,  loja: "Farmácia Carmindo Lamy", link:"http://fb.com/farmacia.carmindolamy" },
              { id:"16", src:Lisboa15,  loja: "Farmácia Cardote", link:"http://fb.com/farmaciacardote" },
              { id:"06", src:Santarem09,  loja: "Farmácia Central", link:"https://www.facebook.com/FarmaciaCentralAlmeirim" },
              { id:"07", src:Faro13,  loja: "Farmácia Central ", link:"https://www.facebook.com/centralarmacaodepera/" },
              { id:"30", src:Lisboa34,  loja: "Farmácia Centro", link:"https://www.facebook.com/farmaciadocentro/" },
              { id:"22", src:Viana02,  loja: "Farmácia Cerqueira", link:"https://www.facebook.com/farmacia.cerqueira/" },
              { id:"01", src:Portalegre02,  loja: "Farmácia Chambel", link:"https://www.facebook.com/pages/category/Pharmacy---Drugstore/Farm%C3%A1cia-Chambel-1420155008214586/" },
              { id:"30", src:Lisboa49,  loja: "Farmácia do Choupal", link:"https://www.facebook.com/pages/category/Health-Beauty/Farm%C3%A1cia-do-Choupal-855875721279037/" },
              { id:"30", src:Lisboa35,  loja: "Farmácia Confiança", link:"https://farmaciaconfiancaonline.pt/contactos/" },
              { id:"05", src:Aveiro07,  loja: "Farmácia Cortegaça", link:"https://www.facebook.com/farmaciacortegaca/" },
              { id:"03", src:Setubal09,  loja: "Farmácia Fontes", link:"https://www.facebook.com/FarmaciaFontes/" },
              { id:"06", src:Santarem10,  loja: "Farmácia da Terra", link:"https://www.facebook.com/farmaciadaterra.entroncamento/" },
              { id:"30", src:Lisboa36,  loja: "Farmácia da Luz", link:"https://www.facebook.com/farmaciadaluz/" },
              { id:"30", src:Lisboa37,  loja: "Farmácia Lisboa", link:"https://www.facebook.com/farmacia.lisboa/" },
              { id:"07", src:Faro11,  loja: "Farmácia Maria Paula", link:"https://www.facebook.com/farmaciaMariaPaula/" },
              { id:"01", src:Braganza01,  loja: "Farmácia Morais Sarmento", link:"https://wwhttps://www.facebook.com/Farm%C3%A1cia-Morais-Sarmento-238263253298833/" },
              { id:"03", src:Setubal04,  loja: "Farmácia Pragal", link:"https://www.facebook.com/farmaciapragal/" },
              { id:"02", src:Leiria05,  loja: "Farmácia Rosa", link:"https://www.facebook.com/farmaciarosacaldasdarainha/" },
              { id:"30", src:Lisboa26,  loja: "Farmácia Varela", link:"https://www.facebook.com/farmaciavarelapt/" },

              
            ]
          },
          {
            region: "Açores",
            resellers:[
              { id:"01", src:Azores01,  loja: "Angra Natur", link:"http://fb.com/praiaeangra" },
              { id:"03", src:Azores03,  loja: "Bioforma", link:"https://www.facebook.com/bioformaacores" },
              { id:"01", src:Azores04,  loja: "Espaço em cena", link:"https://www.facebook.com/pages/Espa%C3%A7o-Em-Cena/846832718785560" },
              { id:"02", src:Azores02,  loja: "Mercado Bio", link:"https://www.facebook.com/Espa%C3%A7o-Terra-e-Cosmos-1960044307574942/" },
              { id:"03", src:Azores05,  loja: "Mercado das Ervas", link:"http://mercadodaservas.com/" },

              
            ]
          },
          {
            region: "Aveiro",
            resellers:[
              { id:"01", src:Aveiro01,  loja: "Farmácia Carmindo Lamy", link:"http://fb.com/farmacia.carmindolamy" },
              { id:"05", src:Aveiro07,  loja: "Farmácia Cortegaça", link:"https://www.facebook.com/farmaciacortegaca/" },
              { id:"05", src:Lisboa53,  loja: "Girassol", link:"http://www.girassol.com/content/7-onde-estamos" },
              { id:"02", src:Aveiro02,  loja: "Mercearia Santiago", link:"https://www.instagram.com/merceariasantiago/" },
              { id:"03", src:Aveiro03,  loja: "Natur ALA", link:"http://fb.com/natur.ala.aveiro" },
              { id:"03", src:Aveiro06,  loja: "Natur Ria", link:"https://www.facebook.com/naturria/" },
              { id:"04", src:Aveiro04,  loja: "Naturvida", link:"http://www.naturvidaaveiro.pt" },
              { id:"05", src:Aveiro05,  loja: "Vertente dos Sabores", link:"http://fb.com/vertentedossabores/" },

              
            ]
          },
          {
            region: "Beja",
            resellers:[
              { id:"01", src:Beja01,  loja: "Solvita Dietética", link:"https://www.facebook.com/solvitabeja/" },
              { id:"02", src:Beja02,  loja: "Zmar", link:"http://www.zmar.eu" },
            ]
          },
          {
            region: "Braga",
            resellers:[
              
              { id:"01", src:Braga01,  loja: "Biobrassica", link:"https://www.biobrassica.pt/" },
              { id:"04", src:Braga05,  loja: "Biocelos", link:"http://www.biocelos.com/" },
              { id:"04", src:Braga08,  loja: "Biojordão", link:"https://www.facebook.com/Biojordaonatural/" },
              { id:"04", src:Braga09,  loja: "Blue Mall Sustainable", link:"https://bluemallsustainable.com/" },
              { id:"02", src:Braga02,  loja: "JAJU Supermercado", link:"http://www.jaju.pt" },
              { id:"04", src:Braga07,  loja: "Infarma Parafarmácia", link:"https://www.facebook.com/infarmaparafarmaciafao/" },
              { id:"06", src:Online44,  loja: "Mipmed", link:"https://mipmed.com/" },
              { id:"03", src:Braga03,  loja: "Natural & Bio", link:"http://www.fb.com/sernaturalbio" },
              { id:"04", src:Braga04,  loja: "Pedra Flor", link:"https://www.instagram.com/pedraflor.pt/" },
              { id:"04", src:Braga06,  loja: "Ortocuidar+", link:"https://www.facebook.com/ortocuidarmais/" },
            ]
          },
          {
            region: "Bragança",
            resellers:[
              { id:"01", src:Braganza01,  loja: "Farmácia Morais Sarmento", link:"https://wwhttps://www.facebook.com/Farm%C3%A1cia-Morais-Sarmento-238263253298833/" },
            ]
          },
          {
            region: "Castelo Branco",
            resellers:[
              { id:"01", src:Castelo01,  loja: "Mercadinho da Aldeia", link:"https://www.facebook.com/mercadinhodaaldeia/" },
              { id:"02", src:Castelo02,  loja: "Terra Bio", link:"https://www.fb.com/TerrabioCB" },
            ]
          },
          {
            region: "Coimbra",
            resellers:[
              { id:"03", src:Coimbra03,  loja: "Aldeia de Xisto Cerdeira", link:"https://www.cerdeirahomeforcreativity.com/" },
              { id:"01", src:Coimbra01,  loja: "Bioescolha", link:"https://www.facebook.com/Bioescolha/" },
              { id:"02", src:Coimbra02,  loja: "Grão Natural", link:"https://www.facebook.com/graonatural.pt/" },
              { id:"02", src:Coimbra04,  loja: "V.V. Granel", link:"https://www.facebook.com/VV-Granel-102480457872015/ " },
            ]
          },
          {
            region: "Évora",
            resellers:[
              { id:"01", src:Evora03,  loja: "Casa Barrocal", link:"https://barrocal.pt/" },
              { id:"01", src:Evora01,  loja: "Manjerona&Companhia", link:" https://www.facebook.com/pages/category/Grocery-Store/Manjerona-C%C2%AA-843205646066067/" },
              { id:"01", src:Evora02,  loja: "OXALÁ", link:"https://www.facebook.com/oxala.evora/" },
            ]
          },
          {
            region: "Faro",
            resellers:[
              { id:"07", src:Faro12,  loja: "Armazém Integral", link:"https://www.facebook.com/armazemintegralagranel/" },
              { id:"07", src:Faro14,  loja: "Cardo", link:"https://www.instagram.com/cardoviladobispo/" },
              { id:"07", src:Faro13,  loja: "Farmácia Central ", link:"https://www.facebook.com/centralarmacaodepera/" },
              { id:"07", src:Faro11,  loja: "Farmácia Maria Paula", link:"https://www.facebook.com/farmaciaMariaPaula/" },
              { id:"07", src:Faro09,  loja: "Grãos de Amor", link:"https://www.instagram.com/graosdeamorlagos/" },
              { id:"07", src:Faro10,  loja: "Hortelã-Pimenta", link:"https://www.instagram.com/hortela_pimenta_lagos/" },
              { id:"07", src:Faro08,  loja: "Loja Feijanito", link:"https://www.facebook.com/lojafeijanito/" },
              { id:"01", src:Faro01,  loja: "Meu Super Vales", link:"https://www.facebook.com/Meu-Super-Vales-1246246038865875/" },
              { id:"02", src:Faro02,  loja: "Mercearia Bio", link:"http://www.merceariabio.pt" },
              { id:"03", src:Faro03,  loja: "Mundo Saudável", link:"http://www.mundosaudavel.pt" },
              { id:"04", src:Faro04,  loja: "Naturboticae", link:"http://www.naturboticae.com" },
              { id:"05", src:Faro05,  loja: "Ortonatura", link:"https://www.facebook.com/pages/category/Shopping---Retail/Ortonatura-230204337155781/" },
              { id:"06", src:Faro06,  loja: "Outro Lado", link:"https://www.facebook.com/Restoutrolado" },
              { id:"07", src:Faro07,  loja: "Retratos d’Aldeia", link:"https://www.facebook.com/retratosdaldeia" },
              { id:"07", src:Faro15,  loja: "things_natura", link:"https://www.facebook.com/thingsnatura/" },

            ]
          },
          {
            region: "Guarda",
            resellers:[
              { id:"01", src:Guarda01,  loja: "ADN Bio", link:"https://www.adnbio.pt/" },
              { id:"01", src:Guarda02,  loja: "Consultório Sampaio e Melo", link:"https://www.facebook.com/sampaioemelo.consultorio" },
            ]
          },
          {
            region: "Leiria",
            resellers:[
              { id:"01", src:Leiria01,  loja: "Bio Center", link:"https://www.facebook.com/eliab.danielac" },
              { id:"02", src:Leiria02,  loja: "Bioescolha", link:"https://www.facebook.com/bioescolhaleiria" },
              { id:"02", src:Leiria03,  loja: "Bio's Mercado Biológico", link:"https://www.facebook.com/BiOsCaldasRainha/" },
              { id:"02", src:Leiria07,  loja: "Clinica Guimarota", link:"https://www.facebook.com/Clinica-M%C3%A9dica-e-Dent%C3%A1ria-da-Guimarota-1438195786397349/" },
              { id:"02", src:Leiria04,  loja: "Equilibra Bio", link:"https://www.instagram.com/equilibrabio/" },
              { id:"02", src:Leiria05,  loja: "Farmácia Rosa", link:"https://www.facebook.com/farmaciarosacaldasdarainha/" },
              { id:"02", src:Leiria06,  loja: "Granel da Rainha", link:"https://www.instagram.com/graneldarainha/" },
              { id:"02", src:Leiria11,  loja: "Granola - Mercearia Saudável", link:"https://www.facebook.com/granolamerceariasaudavel/" },
              { id:"02", src:Leiria09,  loja: "Intermarché", link:"https://www.facebook.com/intermarcheMG/" },
              { id:"02", src:Leiria10,  loja: "Loja 3P", link:"https://www.facebook.com/loja3P/" },
              { id:"02", src:Leiria08,  loja: "Origens a Granel", link:"https://www.instagram.com/origensagranel.leiria/?hl=pt" },
            ]
          },

          {
            region: "Lisboa",
            resellers:[
              { id:"01", src:Lisboa01,  loja: "Agriloja", link:"http://www.agriloja.pt" },
              { id:"02", src:Lisboa02,  loja: "Amante do Equilíbrio", link:"http://www.amantedoequilibrio.pt" },
              { id:"04", src:Lisboa03,  loja: "Be U", link:"https://www.facebook.com/Be.U.Ericeira" },
              { id:"05", src:Lisboa04,  loja: "Bio Alma", link:"https://www.bioalma.pt" },
              { id:"06", src:Lisboa05,  loja: "BioCourela", link:"https://www.facebook.com/biocourela" },
              { id:"07", src:Lisboa06,  loja: "BIOino", link:"https://www.facebook.com/bioino.merceariabio/" },
              { id:"30", src:Lisboa27,  loja: "Biofrade", link:"https://www.facebook.com/biofrade.agribio/" },
              { id:"09", src:Lisboa08,  loja: "Bolinha de Sabão", link:"http://facebook.com/bolinhadesabao47" },
              { id:"10", src:Lisboa09,  loja: "Bomercado", link:"http://www.fb.com/bomercado247" },
              { id:"11", src:Lisboa10,  loja: "Bosque Feliz", link:"http://www.bosquefeliz.pt" },
              { id:"12", src:Lisboa11,  loja: "Casa a Granel", link:"https://www.facebook.com/casaagranel/" },
              { id:"30", src:Lisboa44,  loja: "Celeiro Bucelas", link:"https://www.facebook.com/Celeiro-Bucelas-303662090265801/" },
              { id:"30", src:Lisboa50,  loja: "Centro Dietético Alverquense", link:"https://www.facebook.com/Centro-Diet%C3%A9tico-Alverquense-208289245852534/" },
              { id:"13", src:Lisboa12,  loja: "Clínicas Viver", link:"http://clinicasviver.com.pt" },
              { id:"30", src:Lisboa47,  loja: "Despensa d'Aromas", link:"https://www.facebook.com/despensadaromas/" },
              { id:"14", src:Lisboa13,  loja: "Ervanária São João de Brito", link:"http://fb.com/ervsjbrito" },
              { id:"15", src:Lisboa14,  loja: "Espaço Metamorphoses", link:"http://fb.com/metamorphosesalt" },
              { id:"30", src:Lisboa32,  loja: "Eco Lógica", link:"https://www.instagram.com/ecologica.loja172/" },
              { id:"30", src:Lisboa45,  loja: "EcoResort Bukubaki", link:"https://www.bukubaki.com/" },
              { id:"30", src:Lisboa29,  loja: "Farmácia Benfica", link:"https://www.facebook.com/farmaciabenfica/" },
              { id:"16", src:Lisboa15,  loja: "Farmácia Cardote", link:"http://fb.com/farmaciacardote" },
              { id:"30", src:Lisboa33,  loja: "Farmácia Carnide", link:"https://www.facebook.com/farmaciacarnide/" },
              { id:"30", src:Lisboa34,  loja: "Farmácia Centro", link:"https://www.facebook.com/farmaciadocentro/" },
              { id:"30", src:Lisboa35,  loja: "Farmácia Confiança", link:"https://farmaciaconfiancaonline.pt/contactos/" },
              { id:"30", src:Lisboa49,  loja: "Farmácia do Choupal", link:"https://www.facebook.com/pages/category/Health-Beauty/Farm%C3%A1cia-do-Choupal-855875721279037/" },
              { id:"30", src:Lisboa36,  loja: "Farmácia da Luz", link:"https://www.facebook.com/farmaciadaluz/" },
              { id:"30", src:Lisboa37,  loja: "Farmácia Lisboa", link:"https://www.facebook.com/farmacia.lisboa/" },
              { id:"30", src:Lisboa26,  loja: "Farmácia Varela", link:"https://www.facebook.com/farmaciavarelapt/" },
              { id:"30", src:Lisboa38,  loja: "Gallery House", link:"https://www.facebook.com/GalleryhouseLisboa/" },
              { id:"05", src:Lisboa53,  loja: "Girassol", link:"http://www.girassol.com/content/7-onde-estamos" },
              { id:"28", src:Lisboa24,  loja: "#Granel – Mercearia Biológica", link:"http://www.hashtaggranel.pt" },
              { id:"30", src:Lisboa28,  loja: "Granel da Linha", link:"https://www.instagram.com/graneldalinha/" },
              { id:"30", src:Lisboa40,  loja: "Jardim Zoológico", link:"https://www.zoo.pt/" },
              { id:"30", src:Lisboa39,  loja: "Halomedic", link:"http://www.halomedic.pt/" },
              { id:"30", src:Lisboa41,  loja: "Living Wine Roma", link:"https://www.facebook.com/Living-Wine-111421118879495/" },
              { id:"17", src:Lisboa16,  loja: "Loja Consi.go", link:"https://www.facebook.com/lojaconsi.go/" },
              { id:"18", src:Lisboa17,  loja: "Luadia Terapias", link:"https://luadiaterapias.wixsite.com/luadia" },
              { id:"18", src:Lisboa52,  loja: "Ludi Center", link:"https://www.ludicenter.pt/" },
              { id:"06", src:Online36,  loja: "Maria do Mar", link:"https://mariadomarshop.com/" },
              { id:"30", src:Lisboa30,  loja: "Mercearia 29", link:"https://www.facebook.com/mercearia29/" },
              { id:"30", src:Lisboa46,  loja: "Mercado do Campo", link:"https://www.facebook.com/mercadodocampopt/" },
              { id:"30", src:Lisboa48,  loja: "Mercado Natura", link:"https://www.facebook.com/mercadonatura.pt/" },
              { id:"19", src:Lisboa18,  loja: "Miosótis", link:"http://biomiosotis.pt" },
              { id:"30", src:Lisboa31,  loja: "ML Clinic", link:"https://www.facebook.com/mafaldaloboclinic/" },
              { id:"30", src:Lisboa43,  loja: "Museu da Água", link:"https://www.facebook.com/museuagua/" },
              { id:"30", src:Lisboa51,  loja: "Naturgolfinho", link:"https://www.facebook.com/naturgolfinho/" },
              { id:"21", src:Lisboa19,  loja: "Porta 88", link:"https://www.facebook.com/portaoitentaeoito/" },
              { id:"30", src:Lisboa42,  loja: "Renovar a Mouraria", link:"https://www.facebook.com/renovar.a.mouraria/" },
              { id:"22", src:Lisboa20,  loja: "Somos Bio", link:"https://www.somosbio.pt" },
              { id:"29", src:Lisboa25,  loja: "Sabores com Alma", link:"https://www.facebook.com/saborescomalmaCascais/" },
              { id:"23", src:Lisboa21,  loja: "The CRU", link:"http://thecru.pt" },
              { id:"26", src:Lisboa22,  loja: "Wave 269", link:"https://www.facebook.com/mardoisseisnove/" },





              

            ]
          },
          {
            region: "Madeira",
            resellers:[
              { id:"01", src:Madeira01,  loja: "Freshbio", link:"http://www.freshbio.pt" },
              { id:"01", src:Madeira02,  loja: "Bioforma", link:"https://www.bioforma.pt/" },
              { id:"01", src:Madeira03,  loja: "Ideias Saudáveis", link:"https://www.facebook.com/Ideiasaudaveis/" },
              { id:"01", src:Madeira04,  loja: "Loja Jacarandá", link:"https://www.facebook.com/Loja-Jacarand%C3%A1-295078080918016/" },
              { id:"01", src:Madeira05,  loja: "Solobio", link:"https://www.facebook.com/Solobio.Madeira/" },
            ]
          },
          {
            region: "Portalegre",
            resellers:[
              { id:"01", src:Portalegre02,  loja: "Farmácia Chambel", link:"https://www.facebook.com/pages/category/Pharmacy---Drugstore/Farm%C3%A1cia-Chambel-1420155008214586/" },
              { id:"01", src:Portalegre01,  loja: "Mercado Português", link:"https://www.instagram.com/mercadoportugues/?hl=pt" },
            ]
          },
          {
            region: "Porto",
            resellers:[
              { id:"05", src:Porto05,  loja: "Biomercado", link:"https://www.facebook.com/Biomercado" },
              { id:"01", src:Porto01,  loja: "Bio Place", link:"https://www.facebook.com/bioplace.pt/" },
              { id:"04", src:Porto04,  loja: "Clinica Só para Sonhos", link:"http://www.soparasonhos.pt/" },
              { id:"02", src:Porto02,  loja: "Green Diamond", link:"https://www.greendiamond.pt" },
              { id:"06", src:Porto07,  loja: "Loja Maçaroca", link:"https://www.facebook.com/macarocamerceariaviva/" },
              { id:"03", src:Porto03,  loja: "Medifarma", link:"https://www.facebook.com/DraMiguela/" },
              { id:"06", src:Porto06,  loja: "Natural Viver", link:"https://www.facebook.com/pages/category/Beauty--Cosmetic---Personal-Care/Natural-Viver-848865005466931/" },
              { id:"06", src:Porto11,  loja: "Nina Coopstore", link:"https://www.facebook.com/ninacoopstore" },
              { id:"06", src:Porto08,  loja: "Queres uma bolachada?", link:"https://www.facebook.com/queresumabolachada/" },
              { id:"06", src:Porto12,  loja: "Supermercado Mira Ramos", link:"https://www.facebook.com/supermercadomiraramos" },
              { id:"06", src:Porto10,  loja: "Vegana by Tentúgal", link:"https://www.facebook.com/VeganabyTentugal/" },
              { id:"06", src:Porto09,  loja: "The Yeatman Hotel", link:"https://www.the-yeatman-hotel.com/pt/" },


            ]
          },
          {
            region: "Santarém",
            resellers:[
              { id:"01", src:Santarem01,  loja: "Bio Thomar", link:"http://www.fb.com/BioThomar" },
              { id:"02", src:Santarem02,  loja: "BioVó", link:"http://www.biovo.pt" },
              { id:"03", src:Santarem03,  loja: "Celeiro Integral", link:"http://www.celeirointegral.pt" },
              { id:"06", src:Santarem07,  loja: "Drogaria Nova", link:"https://www.facebook.com/drogarianova1943" },
              { id:"06", src:Santarem13,  loja: "Granel com Alma", link:"https://www.instagram.com/granel.com.alma/" },
              { id:"05", src:Santarem05,  loja: "Nutribem", link:"https://www.facebook.com/nutribemlda/" },
              { id:"06", src:Santarem09,  loja: "Farmácia Central", link:"https://www.facebook.com/FarmaciaCentralAlmeirim" },
              { id:"06", src:Santarem10,  loja: "Farmácia da Terra", link:"https://www.facebook.com/farmaciadaterra.entroncamento/" },
              { id:"06", src:Santarem12,  loja: "LuxHotels", link:"https://www.luxhotels.pt/" },
              { id:"06", src:Santarem11,  loja: "Naturfil", link:"https://www.facebook.com/naturfilervanaria/" },
              { id:"06", src:Santarem06,  loja: "Pharma Scalabis", link:"https://www.pharmascalabis.com.pt/" },
              { id:"04", src:Santarem04,  loja: "Tonsai Bio", link:"http://fb.com/tonsai.bio" },
              { id:"06", src:Santarem08,  loja: "Xô stress", link:"https://www.facebook.com/xo.stress.3" },


            ]
          },
          {
            region: "Setúbal",
            resellers:[
              { id:"03", src:Setubal11,  loja: "A Horta", link:"https://www.facebook.com/ahorta.agranel/" },
              { id:"03", src:Setubal05,  loja: "Bioalma", link:"https://www.facebook.com/MerceariaBioalma/" },
              { id:"03", src:Setubal08,  loja: "Biovilla Sustentabilidade", link:"https://www.facebook.com/biovilla.sustentabilidade/" },
              { id:"02", src:Setubal01,  loja: "Ervanária Essência Nature", link:"http://www.essencianature.pt" },
              { id:"03", src:Setubal12,  loja: "Ervanária Kolibrie", link:"https://www.facebook.com/Ervanaria-Kolibrie-342000406336914/" },
              { id:"03", src:Setubal09,  loja: "Farmácia Fontes", link:"https://www.facebook.com/FarmaciaFontes/" },
              { id:"03", src:Setubal04,  loja: "Farmácia Pragal", link:"https://www.facebook.com/farmaciapragal/" },
              { id:"03", src:Setubal15,  loja: "Grãos e folhas", link:"https://www.facebook.com/graosfolhas/" },
              { id:"03", src:Setubal13,  loja: "Loja 10afios", link:"https://www.facebook.com/10AfiosSesimbra/" },
              { id:"03", src:Setubal10,  loja: "Mercearia Castiça", link:"https://www.instagram.com/merceariacastica/" },
              { id:"03", src:Setubal06,  loja: "Mercearia Flor do Sol", link:"https://www.facebook.com/merceariaflordosol/" },
              { id:"03", src:Setubal03,  loja: "Mercearia Gomes", link:"https://www.facebook.com/merceariagomescomporta/" },
              { id:"03", src:Setubal02,  loja: "Natur Loja Barreiro", link:"http://facebook.com/NaturLojaBarreiro" },
              { id:"03", src:Setubal07,  loja: "O Passado Português", link:"https://www.facebook.com/opassadoportuguestradicional/" },
              { id:"03", src:Setubal14,  loja: "Setúbal in Art", link:"https://www.facebook.com/setubalinart/" },
            ]
          },
          {
            region: "Viana do Castelo",
            resellers:[
              { id:"22", src:Viana02,  loja: "Farmácia Cerqueira", link:"https://www.facebook.com/farmacia.cerqueira/" },
              { id:"22", src:Viana03,  loja: "La Familia", link:"https://www.instagram.com/lafamilia.vianadocastelo/" },
              { id:"22", src:Viana01,  loja: "Pureza Mercearia", link:"https://www.facebook.com/merceariapureza/" },

            ]
          },
          {
            region: "Vila Real",
            resellers:[
              { id:"22", src:Vila01,  loja: "Casa da Levada", link:"https://www.casasdalevada.com/index.php/pt/" },
              { id:"22", src:Online23,  loja: "Porta dos Sentidos", link:"https://www.porta-dos-sentidos.pt/" },
            ]
          },
          {
            region: "Viseu",
            resellers:[
              { id:"02", src:Viseu04,  loja: "A Horta Biológica", link:"https://www.facebook.com/pages/category/Health-Food-Store/A-Horta-Bio-341433035974193/" },
              { id:"01", src:Viseu01,  loja: "BeiraBio", link:"http://fb.com/Beirabio" },
              { id:"02", src:Viseu02,  loja: "LafoNatura", link:"http://fb.com/Lafonatura" },
              { id:"02", src:Viseu05,  loja: "RebelKids", link:"https://www.facebook.com/rebelkidsviseu/" },
              { id:"02", src:Viseu03,  loja: "Viae Naturalis", link:"https://www.facebook.com/viaenaturalis/" },

            ]
          },
          {
            region: "Spain",
            resellers:[
              { id:"29", src:Spain01,  loja: "LafoNatura", link:"http://fb.com/Lafonatura" },

            ]
          },
          {
            region: "UK",
            resellers:[
              { id:"29", src:Uk01,  loja: "LafoNatura", link:"http://fb.com/Lafonatura" },
            ]
          },
          {
            region: "Greece",
            resellers:[
              { id:"29", src:Uk01,  loja: "LafoNatura", link:"http://fb.com/Lafonatura" },
            ]
          },
          {
            region: "Dutch",
            resellers:[
              { id:"29", src:Uk01,  loja: "LafoNatura", link:"http://fb.com/Lafonatura" },
            ]
          },
          {
            region: "Swiss",
            resellers:[
              { id:"29", src:Uk01,  loja: "LafoNatura", link:"http://fb.com/Lafonatura" },
            ]
          },
          {
            region: "Croatia",
            resellers:[
              { id:"29", src:Uk01,  loja: "LafoNatura", link:"http://fb.com/Lafonatura" },
            ]
          },
          {
            region: "Latvia",
            resellers:[
              { id:"29", src:Uk01,  loja: "LafoNatura", link:"http://fb.com/Lafonatura" },
            ]
          },
        ],
        ptregions:[
          "Online",
          "Farmácias",
          "Açores",
          "Aveiro",
          "Beja",
          "Braga",
          "Bragança",
          "Castelo Branco",
          "Coimbra",
          "Évora",
          "Faro",
          "Guarda",
          "Leiria",
          "Lisboa",
          "Madeira",
          "Portalegre",
          "Porto",
          "Santarém",
          "Setúbal",
          "Viana do Castelo",
          "Vila Real",
          "Viseu",
        ],
        countries:[
          "Espanha",
          "Grécia",
          "Paises Baixos",
          "Portugal",
          "Reino Unido",
          "Suiça",
        ],
        current:{
          region:"Europe", //this.state.resellers[0].region,
          resellers:[
            { id:"99", src:"",  loja: "", link:"" },
          ],  //this.state.resellers[0].resellers,
          },
         }
         this.handleRegionChange = this.handleRegionChange.bind(this)
         this.handleRegionItemsChange = this.handleRegionItemsChange.bind(this)
         this.handleSubmit = this.handleSubmit.bind(this)
         this.handleAddition = this.handleAddition.bind(this)

        }



 


  handleRegionChange(region){
    console.log(region)
    let resellers = this.state.resellers 
    for(let i = 0; i<resellers.length; i++){
      if(resellers[i].region === region){
        this.setState({
          current:{
            region: resellers[i].region,
            resellers: resellers[i].resellers,}
        })
      }
    }
    if(region === "Spain" || region === "UK" || region === "Portugal" || region === "Dutch" || region ==="Greece" || region === "Swiss" || region === "Croatia" || region === "Latvia"){
      this.handleRegionItemsChange(region)
    }
    let imageStart
    let imageText = "Estamos presentes en este país a través de una red variada de socios. ¡Pregúntanos cuáles! ¿Quieres ser distribuidor Babu? Rellena los datos a continuación"
    switch (region){
        case "Portugal": 
             imageStart = Portugal;
          break;
        case "Spain": 
             imageStart = Spain;
          break;
        case "Croatia": 
             imageStart = Croatia;
          break;
        case "Latvia": 
             imageStart = Latvia;
          break;
        case "Dutch": 
             imageStart = Dutch;
          break;
        case "UK": 
             imageStart = Uk;
          break;
        case "Greece": 
             imageStart = Greek;
          break;
        case "Swiss": 
             imageStart = Swiss;
          break;
        default:
              imageStart = Portugal;
              imageText= " Estamos presentes um pouco por toda a parte do mundo! Clique nos botões à esquerda e descubra onde pode encontrar a Babu!"
              break;
    }
    this.setState({
      imageStart: imageStart,
      imageText : imageText
    })
    
  }

  handleRegionItemsChange(region){
    let regionWithItems = region;
    this.setState({
      regionWithItems:regionWithItems
    })
    
  }


  handleAddition(e){
    this.setState({
      [e.target.name]: e.target.value,
    });
  }



  handleSubmit(e) {
    e.preventDefault();
    this.setState({
    contactEmail: '',
    contactMessage: ''
    });

    $.ajax({
    url: process.env.NODE_ENV !== "production" ? '/getMail' : "mailer.php",
    type: 'POST',
    data: {
        'name': this.state.name,
        'email': this.state.email,
        'hassubject': true,
        'subject': 'Página Revendedores | Site Babueco.com' ,
        'message': this.state.message,
    },
    cache: false,
    success: function(data) {
        // Success..
        this.setState({
        contactEmail: 'success',
        contactMessage: '<div class="sucess_contact"><h2>Su mensaje ha sido recibido!</h2><p>¡Nuestros agentes se pondrán en contacto con usted lo antes posible!</p></div>'
        });
        $('#formContact').slideUp();
        $('#formContact').after(this.state.contactMessage);
        console.log('success', data);
    }.bind(this),
    // Fail..
    error: function(xhr, status, err) {
        console.log(xhr, status);
        console.log(err);
        this.setState({
        contactEmail: 'danger',
        contactMessage: '<h1>Lo sentimos, pero hubo un error en el algoritmo.</h1><p>Inténtalo de nuevo más tarde o comunícate con info@babueco.com</p>'
        });
        console.log(this.state.email + this.state.message + 'fail');
    }.bind(this)
    })
}


  render() {
    return (
      <section className="titlewrapper resellers-overflow resellers">
        <section className="hero">
          <div className="cta__">
              <div className="cta__image"> <img className="diagonal--inverted" src={fotoproduct} alt=""/></div>
              <div className="cta__containertext">
                  <div className="container__text--white">
                      <h2>Dónde encontrar</h2>
                  </div>
              </div>
          </div>
        </section>
        <article className="resell">
          <div className="resell__left">
            
            <div className={this.state.regionWithItems === "Spain" ? "main--second country button__babu--light" : "country button__babu--light"} onClick={()=>this.handleRegionChange('Spain')}> <span>España</span></div>
            <div className={this.state.regionWithItems === "Croatia" ? "main--second country button__babu--light" : "country button__babu--light"} onClick={()=>this.handleRegionChange('Croatia')}> <span>Croacia</span></div>
            <div className={this.state.regionWithItems === "Greece" ? "main--second country button__babu--light" : "country button__babu--light"} onClick={()=>this.handleRegionChange('Greece')}> <span>Grecia</span></div>
            <div className={this.state.regionWithItems === "Latvia" ? "main--second country button__babu--light" : "country button__babu--light"} onClick={()=>this.handleRegionChange('Latvia')}> <span>Letonia</span></div>
            <div className={this.state.regionWithItems === "Dutch" ? "main--second country button__babu--light" : "country button__babu--light"} onClick={()=>this.handleRegionChange('Dutch')}> <span>Paises Bajos</span></div>
            <div className={this.state.regionWithItems === "Portugal" ? "main--second country button__babu--light" : "country button__babu--light"} onClick={()=>this.handleRegionChange('Portugal')}><span>Portugal</span></div>
            <div className="retail">
              <div className="retail__online">
                <ul className={this.state.regionWithItems === "Portugal" ? "presential" : "presential closed"}>
                  {this.state.resellers.map((item,index)=>{
                    if(item.region !== 'Spain' && item.region !== 'UK' && item.region !== 'Swiss' && item.region !== 'Dutch' && item.region !== 'Greece' && item.region !== "Croatia" && item.region!== "Latvia"){
                      return <li key={index} onClick={()=>this.handleRegionChange(item.region)} className={this.state.current.region === item.region ? "main--second" : ""}>{item.region}</li>

                    }
                    else{
                      return null;
                    }
                  }
                    )}

                </ul>
              </div>
            </div>
            <div className={this.state.regionWithItems === "UK" ? "main--second country button__babu--light" : "country button__babu--light"} onClick={()=>this.handleRegionChange('UK')}><span>Reino Unido</span></div>
            <div className={this.state.regionWithItems === "Swiss" ? "main--second country button__babu--light" : "country button__babu--light"} onClick={()=>this.handleRegionChange('Swiss')}><span>Suiza</span></div>

          </div>
          <div className="resell__right">
              <div className="resell__showoff">
                <div className="showoff__container">
                  { this.state.regionWithItems === "Portugal" ? 

                        this.state.ptregions.includes(this.state.current.region)   ?

                        this.state.current.resellers.map((item,index)=>{
                          return <a key={index + "place"} target="_blank" rel="noopener noreferrer" href={item.link}><img src={item.src} alt=""/></a>
                        })

                      :
                        <div className="not-portugal">
                          <img src={this.state.imageStart} alt="" />
                          <p> {this.state.imageText}</p>
                        </div>
                    :
                    


                    <div className="not-portugal" >
                      <img src={this.state.imageStart} alt=" country" />
                      <p>
                          {this.state.imageText}
                      </p>
                    </div>
                  
                  
                  
                  }
                
                </div>
              </div>
          </div>
          <div class="occupant"></div>
          <div className="container">
            <div className="container_title">
              <h2>¿Quieres ser distribuidor Babu? </h2>
            </div>
            <form className="contactus__form" id="formContact" action="mailer.php" onSubmit={this.handleSubmit}>
                <label htmlfor="name"> <span>Nombre</span>
                    <input type="text" name="name" value={this.state.name} onChange={this.handleAddition} required />
                </label>
                <label htmlfor="email"> <span>E-mail</span>
                    <input type="email" name="email" value={this.state.email} onChange={this.handleAddition} required/>
                </label>
                <label htmlfor="message"> <span>Mensaje</span>
                    <textarea name="message" value={this.state.message} onChange={this.handleAddition} required></textarea>
                </label>
                <label htmlfor="privacy">
                  <div className="privacy_sub"><input type="checkbox" name="privacy" onChange={this.handleAddition} required/><p><span>He leído y entendido la </span> <a href="https://shop.babueco.com/es/politica-de-privacidad/">Politica de Privacidad</a><span> de Babu.</span></p></div>
                </label>
                  <input className="button__babu" type="submit" value="Enviar" />
            </form>
        </div> 



        </article>
        <section className="contactus">
          <div className="resell_left"></div>
         
        </section>
      </section>
    )
  }
}

export default Resell
