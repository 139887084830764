import React, {Component} from "react";
import { BrowserRouter as Router, Route, Link} from "react-router-dom";

import Cookies from 'js-cookie'


/*Images*/ 
import conta from './../../assets/icons/icon-perfil.png'
import cart from './../../assets/icons/icon-carrinho.png'

/* Test Imports */ 


import logo from "./../../assets/logos/babulogo.png"

const Selector = ()=>{

  let language = localStorage.getItem("chosenlanguage");


  const langChange = (e)=>{
    let lang = e.target.value;
    console.log(e.target.value);
    localStorage.setItem("chosenlanguage", lang)
    switch(lang){
      case 'pt-PT':
          document.getElementById("pt").click();break;
      case 'es-ES':
          document.getElementById("es").click();break;
      case 'fr-FR':
          document.getElementById("fr").click();break;
      default:
          document.getElementById("en").click();break;
  }
  }
 return(
  <div className="selector-wrapper">
    <select name="lang" id="langSelect" value={language} onChange={(e)=>langChange(e)}>
      <option value="en-GB">EN</option>
      <option value="es-ES">ES</option>
      <option value="fr-FR">FR</option>
      <option value="pt-PT">PT</option>
    </select>
  </div>
 )
}

class Header extends Component {
    constructor(props,context){
        super(props,context)
        this.state={
          openClose:false,
          lang:"",
        }
        this.openCloseMenu = this.openCloseMenu.bind(this)
        this.langChange = this.langChange.bind(this)
    
      }
      
      componentDidMount(){
        switch(localStorage.getItem("chosenlanguage")){
          case "en-GB": this.setState({lang:"EN"}); break;
          case "es-ES": this.setState({lang:"ES"}); break;
          case "fr-FR": this.setState({lang:"FR"}); break;
          case "pt-PT": this.setState({lang:"PT"}); break;
          default: this.setState({lang:"EN"}); break;
        }
      }
    
      componentWillUnmount(){
        this.catchingIndex();
      }

      langChange(e){
        let lang = e.target.value;
        this.setState({lang});
        localStorage.setItem("chosenlanguage", lang)
      }
    
      openCloseMenu(event){
        
        this.setState({openClose: !this.state.openClose})
        let body = document.getElementsByTagName('body')[0]
        body.classList.toggle('menu-open');
        if(body.classList.contains('menu-open') === this.state.openClose){
          body.classList.toggle('menu-open');
        }
    
      }
      
      catchingIndex(theindex){
        this.setState({indexservices:theindex})
      
      }
      
  render() {
    let url='https://blog.babueco.com';
    let item_count = Cookies.get('shopCartCount');

    return (

              <header className="menu">
              <div className="abovemenu"><p>Envio gratuito em encomendas superiores a 10€ </p></div>
              <nav className="navegacao">
                  <div className="navegacao__logo">
                      <Link to="/"><img src={logo} alt=""/></Link>
                  </div>

                  <div className="navegacao__menus">
                    <Router>
                      <Selector />
                    </Router>
                    <div className="menus__perfil icon">
                        <a href="https://shop.babueco.com/minha-conta/">
                        <img src={conta} alt=""/>
                        </a>
                    </div>
                    <div className="menus__carrinho icon">
                        <a href="https://shop.babueco.com/carrinho/">
                        <img src={cart} alt=""/>
                        {item_count ? <span className="bolinha">{item_count}</span> : ""}
                        </a>
                    </div>
                    <div className="blog icon">
                        <a target="_blank" rel="noopener noreferrer" href={url}>
                          <span>Blog</span>
                        </a>
                    </div>
                      <div className="menus__hamburger" >
                          <div className="hamburger"   onClick={this.openCloseMenu} ><span></span></div>
                          <div className={ this.state.openClose ? "itens__hamburger opened" : "itens__hamburger"} onClick={this.openCloseMenu}>
                            <ul >
                              {/*<a href="https://natal.babueco.com/" className="neat_feature" target="_blank"><li className="menu-item">Quebra-Nozes</li></a>*/}
                              <OldSchoolMenuLink activeOnlyWhenExact={true} to="/" label="Home"  onClick={this.openCloseMenu} />
                              <OldSchoolMenuLink to="/produtos" label="Produtos"  onClick={this.openCloseMenu} />
                              <OldSchoolMenuLink to="/loja" label="Loja"  onClick={this.openCloseMenu} />
                              <OldSchoolMenuLink to="/porque" label="Porquê Babu"  onClick={this.openCloseMenu} />
                              <OldSchoolMenuLink to="/sobre" label="Quem somos"  onClick={this.openCloseMenu} />
                              <OldSchoolMenuLink to="/causas" label="Causas sociais"  onClick={this.openCloseMenu} />
                              <OldSchoolMenuLink to="/perguntasfrequentes" label="Perguntas frequentes"  onClick={this.openCloseMenu} />
                              <OldSchoolMenuLink to="/onde" label="Onde encontrar"  onClick={this.openCloseMenu} />
                              <OldSchoolMenuLink to="/contactos" label="Contactos"  onClick={this.openCloseMenu} />
                            </ul>
                            <div className="filler"></div>
                          </div>
                      </div>
                  </div>
              </nav>
      </header> 

    )
  }
}

const OldSchoolMenuLink = ({ label, to, activeOnlyWhenExact, onClick }) => (
    <Route
      path={to}
      exact={activeOnlyWhenExact}
      children={({ match }) => (
        <li  onClick={onClick}  className={match ? "menu-item active" : "menu-item"}>
          <Link to={to} className="menu-item-in" >{label}</Link>
        </li>
      )}
    />
  );


export default Header
